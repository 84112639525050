import './Footer.scss';

import Logo from '../../components/Logo/Logo';

import { useLocation } from 'react-router-dom';
import Socials from '../../components/Socials/Socials';

const Footer = () => {
  const location = useLocation();



  const handleLinkClick = e => {
    e.preventDefault();
    const targetId = e.target.getAttribute('href');
    if (targetId === '' || targetId.includes('/')) {
      return;
    }
    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer">
      <div className="footer__about">
      <Logo>
        <span>Red{'\n'}Gud</span>
      </Logo>
        <p className="footer__about-copyright">
          © 2024 by Red.
          <br />All rights reserved
        </p>
      </div>
    <Socials />
    </footer>
  );
};

export default Footer;
