import './Layout.scss';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';


const Layout = ({ children, address, setAddress }) => {
  return (
    <div className="layout">
      <Header address={address} setAddress={setAddress} />
      <main>{children}</main>
      <Footer />

    </div>
  );
};

export default Layout;
