import './Logo.scss';

import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png';

const Logo = ({children}) => {
  return (
    <Link to="/" className="logo">
      <img src={logo} alt="logo" className="logo__img" />
      <h3 className="logo__title">{children}</h3>
    </Link>
  );
};

export default Logo;
