import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.scss';

import Loader from './components/Loader/Loader';
import Layout from './layout/Layout/Layout';


const MintNft = lazy(() => import('./pages/MintNft/MintNft'));

function App() {
  const [showApp, setShowApp] = useState(false);

  useEffect(() => {
    window.addEventListener('load', () => {
      setTimeout(() => {
        setShowApp(true);
      }, 100);
    });
  }, []);


  const [address, setAddress] = useState(null);

  return (
    <Suspense fallback={<Loader />}>
      <div className={`app ${showApp ? 'show' : 'hide'}`}>
        <Layout address={address} setAddress={setAddress}>
          <Routes>
            <Route path="/" element={<MintNft address={address} setAddress={setAddress} />} />
          </Routes>
        </Layout>
      </div>
    </Suspense>
  );
}

export default App;
