import Web3 from 'web3';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './ConnectWalletBtn.scss';

import walletIcon from '../../assets/svg/wallet-icon.svg';

const ConnectWalletBtn = ({
  className,
  setIsConnectedWallet,
  address,
  setAddress,
}) => {
  const location = useLocation();
  const formattedAddress = `${address?.slice(0, 4)}...${address?.slice(-4)}`;

  useEffect(() => {
    if (address && setIsConnectedWallet) {
      setIsConnectedWallet(true);
    }
  }, [address, setIsConnectedWallet]);

  const connectWallet = async () => {
    const deepLinkUrl =
      location.pathname === '/nft'
        ? 'https://metamask.app.link/dapp/red-mint.fun'
        : 'https://metamask.app.link/dapp/red-mint.fun';
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();

        console.log('Connected to MetaMask!');
        console.log('Current account:', accounts[0]);
        setAddress(accounts[0]);
      } else if (window.navigator && window.innerWidth < 1024) {
        window.location.href = deepLinkUrl;
      } else {
        console.error('MetaMask not found.');
        alert(
          'MetaMask not found. Please install extension to your browser. If you browsing from mobile device you need to connect wallet manually from MetaMask app browser!',
        );
      }
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    }
  };

  return (
    <button className={`connect-wallet ${className}`} onClick={connectWallet}>
      {address ? (
        <div className="wallet-address">
          <img src={walletIcon} className="wallet-img" alt="wallet" />
          <p>{formattedAddress}</p>
        </div>
      ) : (
        'Connect Wallet'
      )}
    </button>
  );
};

export default ConnectWalletBtn;
