
import './Header.scss';

import Logo from '../../components/Logo/Logo';

import ConnectWalletBtn from '../../components/ConnectWalletBtn/ConnectWalletBtn';

const Header = ({ address, setAddress }) => {




  return (
    <header className="header">
      <Logo>
        <span>Red{'\n'}Pepe</span>
      </Logo>
        <ConnectWalletBtn
          className="header__connect-wallet-btn"
          address={address}
          setAddress={setAddress}
        />


    </header>
  );
};

export default Header;
