import './Socials.scss';

import discord from '../../assets/svg/discord.svg';
import twitter from '../../assets/svg/twitter.svg';

const Socials = () => {
  return (
    <div className='socials__wrap'>
      <ul className="socials__list">
        <li className="socials__item">
          <a href="https://discord.com/invite/RRvRCxpspH" className="socials__link">
            <img src={discord} alt=""  className='socials__icon'/>
            <span>Discord</span>
          </a>
        </li>
        <li className="socials__item">
          <a href="https://twitter.com/REDPEPEsei" className="socials__link">
            <img src={twitter} alt="" className='socials__icon' />
            <span>Twitter</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Socials;
